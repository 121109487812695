import './AboutBlurb.css';
import star from '../assets/images/star.png';
import React from "react";

function AboutBlurb() {

    return (
        <div class="about">
            <div className="blurbParts">
                <div className="blueBall blueBallLeft"></div>
                <div className="blueBall blueBallRight right1"></div>
                <div className="blueBall blueBallRight right2"></div>
                <div className="blueBall blueBallRight right3"></div>
                <div className="blurb upperHeaderBlurb"></div>
                <div className="blurb blurbBody"></div>
                <div className="blurb lowerHeaderBlurb"></div>
                <div className="blurb leftRectangle"></div>
                <div className="blurb rightRectangle"></div>
                <div className="overlapBlurb upperOverlap"></div>
                <div className="overlapBlurb lowerOverlap"></div>
            </div>
            <p className="blurbHeader upperText"> 6 week tech bootcamp</p>
            <p className="blurbHeader lowerText"> for future entrepreneurs</p>
            <p className="blurbSubHeader"> top project will receive</p>
            <p className="blurbSubHeader subHeaderMoney"> $20,000</p>
            <p className="blurbSubHeader subHeaderBottom"> investment from Cerise Technology</p>
            <div className="star">
                <img className="starImg" src={star} alt="neon yellow comic book style star with a black outline"></img>
                <p className="starText"> grades </p>
                <p className="starText secondRow"> 8-12 </p>
                <p className="starText thirdRow"> eligible </p>
            </div>
        </div>
    )
}

export default AboutBlurb;